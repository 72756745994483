/* footer shortcut modal */
.shortcut {
    cursor: pointer;
}

.shortcut-modal {
    background-color: rgba(93, 117, 152, 0.8);

    .modal-dialog {
        max-width: 80%;

        .modal-content {
            border-radius: 15px;

            .close {
                border: 1px solid $primary-dark;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 1;
                background-color: $primary-dark;
                font-size: 27px;
                font-weight: 400;
            }

            .modal-body {
                padding-bottom: 3rem;

                .inner-div {
                    .shortocuts-info {
                        .filled {
                            display: inline-block;
                            margin-right: 5px;
                            padding: 2px;
                            width: 60px;
                            background-color: $primary-dark;
                            color: $white;
                            text-align: center;
                            font-size: 14px;
                        }

                        .unfilled {
                            display: inline-block;
                            margin-right: 5px;
                            color: $primary-dark;
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

/* ctrlspace modal */

.ctrlSpace-modal,
.ctrlSave-modal,
.globalSearchFooter-modal {
    background-color: rgba(255, 255, 255, 0.7);

    .modal-dialog {
        .modal-content {
            background-color: transparent!important;
            border-radius: 15px!important;
            background-size: contain!important;
            background-repeat: no-repeat!important;
            box-shadow : none!important;

            .modal-header{
                .close {
                    color: rgb(16,52,104)!important;
                    padding: 0px!important;
                    margin: -1rem 0rem -1rem auto!important;
                    opacity: 1!important;
                }
            }

            .modal-body {
                display: flex;
                align-items: flex-end;
                padding: 0;
                border-radius: 15px;
                box-shadow: 0 5px 14px 0 rgba(58, 58, 58, 0.2);

                .main-row {
                    position: relative;

                    .search-img {
                        position: absolute;
                        top: 2px;
                        left: 23px;
                        width: 15px;
                        transform: rotate(90deg);
                        color: hsl(216, 41%, 35%) !important;
                        font-size: 1.2rem;
                    }
                }

                .searchField {
                    border-radius: unset;
                    border: 1px solid #b8ccea;
                    padding-left: 60px;
                    color: #556a8a;

                    &::placeholder {
                        color: #556a8a;
                    }
                }

                // .select2-selection {
                //     border-radius: 20px;

                //     .select2-selection__clear {
                //         display: none;
                //     }

                //     .select2-selection__rendered {
                //         padding-left: 3.4rem !important;
                //         color: $primary-dark !important;

                //         .select2-selection__placeholder {
                //             color: $primary-dark !important;
                //         }
                //     }
                // }

                // .select2-container {
                //     width: 100% !important;
                // }
            }
        }
    }
}

.globalSearchFooter-modal {
    .modal-body {
        .dropdown {
            button {
                height: 37px;
            }
        }
    }
}

/* import modal */

.import {
    cursor: pointer;
}

.import-modal {
    background-color: rgba(255, 255, 255, 0.7);

    .modal-dialog {
        max-width: 30%;

        .modal-content {
            border-radius: 15px;
            background-size: 100%;
            background-repeat: no-repeat;

            .modal-header {
                .close {
                    opacity: 1;
                }
            }

            .modal-body {
                padding-top: 8rem;

                #import_label {
                    padding: 0.4rem 1rem;
                    width: 200px;
                    border: 1px solid #D1D5E6;
                    border-radius: 25px;
                    background-color: $white;
                    color: #8590A1;
                    text-align: left;
                }

                .drag-round-bg {
                    cursor: pointer;

                    svg {
                        width: 100px;
                        fill: $primary-dark;
                    }

                    i {
                        width: 100px;
                        color: $primary-dark;
                        font-size: 95px;
                    }
                }

                .form-info {
                    color: #8590A1;
                }
            }
        }
    }
}

/* advance search modal */

.advance-search {
    cursor: pointer;
}

.advance-search-modal {
    background-color: rgba(255, 255, 255, 0.7);

    .modal-dialog {
        max-width: 40%;

        .modal-content {
            height: 75vh;
            border-radius: 15px;
            background-size: 100%;
            background-repeat: no-repeat;

            .modal-body {
                display: flex;
                align-items: flex-end;
                padding: 30px;
                border-radius: 15px;
                box-shadow: 0 5px 14px 0 rgba(58, 58, 58, 0.2);

                form {

                    input,
                    select,
                    textarea {
                        border-radius: 18px;
                        color: #8590A1;

                        &::placeholder {
                            color: #8590A1;
                        }
                    }
                }
            }
        }
    }
}

/* delete popup */
.swal-modal {
    border-radius: 15px;
    padding: 1rem;
    will-change: unset !important;

    .swal-title {
        color: $primary-darker;
        font-size: 1.125rem;
        margin: 0;
    }

    .swal-text,
    .swal-content {
        margin-top: 0;
        color: #212529;
        margin-bottom: 13px;
    }

    .swal-footer {
        text-align: center;
        padding: 0;
        margin: 0;

        .swal-button-container {

            .swal-button--cancel,
            .swal-button--confirm {
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}

#sticky_noteModal {
    overflow-y: hidden;

    .modal-custom {
        height: 100%;
        margin: 0;

        .modal-content {
            height: 100%;
            box-shadow: unset;

            .notes_add {
                background-color: white;
                border: none;

                i {
                    color: $primary-darker;
                }
            }

            #divStickyNotesContainer {
                height: 88vh;

                .each-sticky-note-outer {
                    border: none;
                    border-radius: 10px;

                    .each-sticky-note-delete {
                        border: none;
                    }

                    .each-sticky-note-header {
                        text-align: left;
                    }
                }
            }
        }
    }
}

#managecolumnreset {
    .modal-content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        border-radius: 10px;

        form {
            width: 100%;
        }
    }
}

.popup-background-image {
    border: none;
    border-radius: 15px;
    display: block;

    .modal-header {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding: 10px;
        background-color: unset;
        display: block;

        .close_model {
            margin: 0;
            background-color: unset !important;
            font-size: 24px;
            color: #808080 !important;
        }

        .modal-title {
            padding: 0;
            color: $primary-dark;
            font-size: 18px;
        }
    }

    .modal-body {
        form {
            width: 100%;

            .modal-submit {
                margin: 0 0 0 8px;
            }
        }
    }
}

#machineModal {
    .modal-footer {
        .table-responsive {
            overflow: auto;
        }
    }
}

#MassInventoryAdjustment {
    .table {
        overflow: auto;
    }
}

.flow-chart-modal {
    .box-body {
        height: 500px;
        overflow: auto;

        img {
            height: 1000px;
        }
    }
}

#ui-id-1 {
    z-index: 1050;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    font-family: 'Proxima Nova Regular';
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 50%);
}

.no-license-modal {
    background-color: rgba(255, 255, 255, 0.8);

    .modal-content {
        box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 50%);
    }

    .modal-dialog {
        max-width: 600px;

        .modal-body {
            .left-side {
                .img-div {
                    background-color: #f3f8fe;
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;

                    img {
                        border-top-left-radius: 15px;
                        border-bottom-left-radius: 15px;
                    }
                }
            }

            .right-side {

                h2.box-title {
                    color: $primary-darker;
                    font-size: 24px;
                    line-height: 1;
                    text-transform: capitalize;
                    font-family: "Proxima Nova Bold";
                }

                p {
                    line-height: 1;
                    font-size: 12px;
                }

                form {
                    .select2-selection--single {
                        border: 1px solid $secondary-lighter;
                        font-size: 12px;
                        padding: 0 12px;

                        .select2-selection__rendered {
                            color: $primary-darker;
                            margin-top: 4px;
                        }

                        .select2-selection__arrow {
                            top: 0;
                        }
                    }

                    .form-group {
                        padding: 0;

                        label {
                            color: $secondary-light;
                            font-size: 12px;
                            margin-bottom: 0;
                            font-weight: 400;
                        }

                        .form-control {
                            border: 1px solid $secondary-lighter;
                            font-size: 12px;
                            padding: 0 12px;
                        }
                    }
                }
            }
        }
    }
}
