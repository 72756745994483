@media (min-width: 320px) and (max-width: 767px) {
    .expand-body {
        height: 100% !important;
    }

    .login-page {
        .main-div {
            background-size: cover;
            border-radius: 15px;
        }
    }

    .dashboard-page {
        height: 100%;
    }

    .main-sidebar {
        margin-left: 0;
    }

    footer {
        overflow: auto;

        .footer-ul {
            width: fit-content;
        }
    }

    .add-scroll-sm {
        overflow-x: auto;
        overflow-y: auto;
    }

    .main-reports {
        .main-col {
            .inner-div {
                .right-side {
                    h5 {
                        font-size: 16px;
                    }

                    p {
                        font-size: 0.875rem;
                        line-height: 12px;
                    }
                }
            }
        }
    }

    header,
    .main-sidebar,
    .main-footer {
        display: none;
    }

    .sub-header {
        margin: 0;
        overflow: auto;
        overflow-y: hidden;

        .menu {
            .right-side {

                .sub-header-ul {
                    .sub-header-li {
                        &.show {
                            ul.dropdown-menu {
                                top: 30px !important;
                                left: 0 !important;
                            }
                        }

                        .dropleft {
                            &:hover {
                                .dropdown-menu {
                                    top: 40px;
                                    right: 65%;
                                }
                            }

                            .dropdown-menu {

                                &.show {
                                    top: 40px;
                                    right: 65%;
                                }
                            }

                            .service-report-dropdown-menu {
                                right: 0;
                                top: 47px;
                            }
                        }
                    }
                }
            }

            .left-side {
                .sub-header-ul {
                    li {
                        white-space: nowrap;
                    }

                    .nav-menu {
                        ul.dropdown-menu {
                            top: 30px !important;
                        }
                    }
                }
            }
        }
    }

    .main-wrapper {
        padding-left: 0 !important;

        .content {
            .dataTables_wrapper {
                height: auto!important;

                table {
                    display: block;
                }

                .bottom {
                    justify-content: center;
                    position: unset;
                    width: 100%;

                    .dataTables_info {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .advance-search-modal,
    .shortcut-modal {
        .modal-dialog {
            max-width: 100%;

            .modal-content {
                .modal-body {
                    padding: 0;
                    align-items: center;
                }
            }
        }
    }

    .page-form {
        .custom-col {
            .form-group {
                justify-content: center;
            }
        }

        .combine-sm {
            display: flex;

            label {
                margin-right: 0.5rem;
            }
        }
    }

    .box {
        .box-footer {
            width: 89% !important;
            bottom: 20px;
            justify-content: center;

            .box-shortcut {
                display: none;
            }
        }
    }

    .nav-tabs-custom {
        ul.nav-tabs.nav-wizard {
            display: block;
            overflow-x: auto;
            white-space: nowrap;

            li {
                display: inline-block;
                width: auto;
            }
        }

        .nav-tabs {
            justify-content: center;

            li {
                width: 200px;
            }
        }

        .purchase-content {
            .form-group {
                .sales-service {
                    margin-left: 0 !important;
                }
            }
        }
    }

    .replicate-dash {
        .main-div {
            .modules-icons {
                .rounded-box {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .responsive-wireframe {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        position: relative;

        .page-name {
            position: absolute;
            top: 47px;
            left: 50%;
            text-align: center;
            transform: translate(-50%);
            width: 100%;
        }

        .settings-modal {
            display: none !important;
        }

        &.settings-sidebar-on {
            .sidebar-li {
                display: block;
            }

            #responsive-wireframe-sidebar {
                a[data-toggle="modal"] {
                    display: none;
                }

                .settings-modal {
                    display: block !important;
                }
            }
        }

        .responsive-wireframe-sidebar {
            height: 100%;
            width: 0;
            position: fixed;
            z-index: 1052;
            top: 0;
            left: 0;
            background-color: white;
            overflow-x: hidden;
            transition: 0.5s;
            padding-top: 60px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            box-shadow: 4px 2px 12px -1px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 4px 2px 12px -1px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 2px 12px -1px rgba(0, 0, 0, 0.2);

            .closebtn {
                position: absolute;
                top: 0;
                right: 25px;
                font-size: 36px;
                margin-left: 50px;
            }

            a[data-toggle="modal"],
            .user-name,
            .logout {
                font-size: 18px;
                color: $primary-darker;
                padding: 8px 8px 8px 32px;
                text-decoration: none;
                display: block;
                transition: 0.3s;
                width: 210px;
            }

            .user-name {
                border-bottom: 1px solid #e5e5e5;
                width: auto !important;

                img {
                    border: 1px solid black;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                }

                .text-right {
                    text-align: left !important;
                }
            }

            .modal-dialog {
                width: 100%;
                margin: 0;
            }

            .modal-content {
                background-color: unset;
                border: none;
                box-shadow: unset;

                .modal-body {
                    background-color: transparent;

                    &.sidebar-li {
                        margin-left: 0;
                    }
                }

                .inner-ul {
                    display: block;
                    width: 100%;
                    border-top-left-radius: 15px !important;
                    max-height: 500px;
                    overflow-y: overlay;
                    position: unset;
                    background-color: white;

                    h5 {
                        color: $primary-dark;
                    }

                    .inner-li {
                        .letter-sentence {
                            align-items: center;

                            .img-div {
                                border: 1px solid $primary-dark;
                                padding: 10px;

                                img {
                                    width: 20px;
                                    height: 20px;
                                }
                            }

                            .content-div {
                                .heading {
                                    color: $primary-dark !important;
                                    font-size: 16px;
                                    font-weight: 400;
                                }

                                .description {
                                    display: none;
                                }
                            }

                            &:hover {
                                background-color: $primary-dark;

                                .heading {
                                    color: white !important;
                                }
                            }
                        }
                    }
                }
            }

            .user-login {
                flex-wrap: nowrap;
            }
        }

        .bar {
            svg {
                width: 25px;
            }
        }

        .logo {
            img {
                border-radius: 10px;
                width: 65px;
            }
        }

        .responsive-wireframe-multinav {
            font-size: 24px;

            .more-img {
                height: 30px;
                width: 30px;
            }

            #multinav-modal {
                &.fade {
                    transition: opacity 1s linear;
                }

                .modal-dialog {
                    width: auto;
                }

                svg {
                    border: 1px solid black;
                    width: 40px;
                    height: 40px;
                    border-radius: 7px;
                    background-color: white;
                    padding: 0 10px;
                }

                .modal-content {
                    border-radius: 15px;

                    a[data-toggle="modal"],
                    .anchor {
                        font-size: 16px;
                        color: black;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &:focus {
                            color: black !important;
                        }
                    }

                    .multiple-items {
                        .slick-slide {
                            div:nth-of-type(1) {
                                div {
                                    a {
                                        margin-bottom: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .modal-content {
                .dropdown-menu {
                    display: block;
                    position: unset;
                    transform: unset;
                }
            }

            .quickadd {
                &.modal-dialog {
                    width: auto;
                }

                .modal-content {
                    border-radius: 15px;

                    ::-webkit-scrollbar {
                        display: none !important;
                    }

                    .modal-body {
                        width: 100%;
                        overflow-x: scroll;
                        height: 500px;

                        .quickadd-div {
                            width: 100%;

                            .row {
                                flex-direction: column;

                                .col-2,
                                .col-3 {
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            #favourite,
            #pin {
                .modal-dialog {
                    width: auto;
                }

                .modal-content {
                    border-radius: 15px;
                }

                .bookmark-ul {
                    top: 0;

                    .bookmark-li {
                        .inner-a {
                            .letter-sentence {
                                p {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            #notification {
                .modal-dialog {
                    width: auto;
                }

                .modal-content {
                    border-radius: 15px;

                    .notification-ul {
                        width: 100%;
                    }
                }
            }

            #reminder {
                .modal-dialog {
                    width: auto;
                }

                .modal-content {
                    border-radius: 15px;

                    .reminder-div {
                        width: 100%;
                    }
                }
            }

            #help {
                .modal-content {
                    border-radius: 15px;

                    .help-div {
                        width: 100%;
                    }
                }
            }

            #support {
                .modal-dialog {
                    width: auto;
                }

                .modal-content {
                    border-radius: 15px;

                    .support-div {
                        width: 100%;
                    }
                }
            }
        }
    }

    .tablesorter {
        overflow: auto;
        display: block;
    }

    .master-modal {
        .modal-dialog {
            width: 96%;
        }
    }

    .error-page-414,
    .error-page-404,
    .error-page-500,
    .error-page-302,
    .error-page-403,
    .error-page-504 {
        flex-direction: column;

        .url-img {
            display: flex;
            justify-content: center;

            img {
                width: 80%;
            }
        }
    }

    .main_heading {
        margin-left: 0 !important;
    }

    .suport-table {
        overflow-x: scroll;

        .suport-timing-table {
            display: block;
            width: 700px;
        }
    }

    .form-group {
        .tab-inner-content {
            margin-left: 63px;

            br {
                display: none;
            }
        }

        .addondesc {
            margin-left: 27px;

            br {
                display: none;
            }
        }

        .sales-order-anchor {
            margin-left: 33px !important;
        }

        .sales-order-summary {
            margin-top: -55px !important;
        }
    }

    .modal {

        .modal-dialog {
            width: 95%;
        }
    }



    .sales-proposal-right-side {
        margin-left: 0 !important;
    }

    .sales-dashboard-row,
    .service-dashboard-row,
    .hr-dashboard-row,
    .inventory-dashboard-row,
    .product-info-boxes {
        .header-box-row {
            .header-box {
                width: 100%;
                float: none;
            }
        }
    }

    .form-filter {
        .select-team {
            margin-top: 17px !important;

            .select2 {
                .select2-selection {
                    height: 28px;
                    padding: 4px;

                    .select2-selection__arrow {
                        height: 25px;
                    }
                }
            }
        }

        .select-date {
            margin-top: 18px !important;

            .select-date-input {
                height: 25px !important;
            }
        }
    }

    .ticketSearchBox {
        .search_arrow_box {
            max-width: 100%;

            &::after,
            &::before {
                left: 70%;
            }
        }
    }

    .ticket-inner-page {
        .wrapper {
            .sub-header {
                .with-right-border {
                    white-space: nowrap;
                }
            }
        }
    }

    .ticket-list-content {
        .tab-content {
            .admin-main-content {
                .info-box {
                    .info-box-icon {
                        img {
                            margin: 0;
                        }
                    }

                    .info-box-content {
                        .info-box-number {
                            padding-top: 25%;
                        }
                    }
                }
            }
        }

        .ticket-box {
            .ticket-box-body {
                .ticket-company-image {
                    margin-left: auto;
                }

                .ticket-control-row {
                    .row {
                        flex-wrap: nowrap;
                        overflow-x: auto;
                        max-width: 300px;

                        .priority {
                            white-space: nowrap;
                        }

                        .duedate {
                            width: 30%;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .diff-pagination {
            .box-footer {
                bottom: 20px;
            }
        }

        &.same-page-tab {
            .tab-content {
                .inspection-table {
                    display: block;
                    overflow-x: auto;
                }
            }
        }
    }

    .ticket-inner-content {
        .ticket-inner-left {
            height: auto !important;

            .ticket-order {
                margin-bottom: 0;
            }
        }

        .ticket-inner-right {
            .tab-box {
                .nav-tabs {
                    flex-wrap: nowrap;
                    white-space: nowrap;
                    overflow-x: auto;

                    .ticket-tab {
                        a {
                            padding: 4px;
                        }
                    }

                    .ticket-inner-three-dot-menu {
                        .more-icon-dropdown-menu {
                            height: 150px;
                            overflow-y: auto;
                            position: unset !important;
                        }
                    }
                }

                .tab-content {
                    margin: 10px 0;

                    .tab-pane {
                        margin-bottom: 17rem;

                        .comment-owner-row {
                            margin: 10px 0;
                        }

                        .table-box {
                            margin: 0;
                            width: 100%;
                        }

                        .table-row {
                            margin-left: 0;
                            margin-right: 0;
                        }

                        .box-footer {
                            width: 83% !important;
                            bottom: 20px;
                        }

                        .machine-detail-block {
                            .ticket-add-comments-table {
                                width: 50%;
                                padding-left: 0;
                                padding-right: 15px;
                            }
                        }

                        form {
                            .lost_disqualified_enquiry_detail {
                                .select2-container {
                                    .select2-search__field {
                                        height: 34px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .product-info-boxes {
        ul {
            display: block !important;
        }
    }

    .product-category-counter {
        margin-left: 0 !important;
    }

    .tooltip {
        &.bs-tooltip-top {
            top: 22px !important;

            .arrow::before {
                display: none;
            }
        }
    }

    .sales-dashboard-row {
        .header-box {
            .info-box {
                .new-box-amount {
                    padding: 5px 10px 10px 125px !important;
                }
            }
        }
    }

    .select-width {
        span.select2 {
            width: 200px !important;
        }

        .input-width {
            width: 200px;
        }
    }

    .no-license-modal {
        .modal-body {
            .left-side {
                .img-div {
                    border-top-right-radius: 15px;
                    border-bottom-left-radius: unset;

                    img {
                        border-top-right-radius: 15px;
                        border-bottom-left-radius: unset !important;
                    }
                }
            }
        }
    }

    .support-form {
        div#mobile_screen_loading {
            width: 350px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            top: 0%;
            z-index: 1;

            .mobile-screen-loader {
                background: $primary-dark;
                height: 80px;
                z-index: 1;

                .div#mobile_screen_loading {
                    display: block; 
                }
            }

            div.mobile-progress {
                position: relative;
                height: 0.6rem !important;
                margin: 0 auto;
                border-radius: 10px;

                div.mobile-progress-bar {
                    background-color: $secondary-light;
                    position: absolute;
                    // width: 0px;
                    height: 10px;
                    border-radius: 10px;
                    animation: load 4s infinite linear;
                }
            }

            @keyframes load {
                0% {
                    width: 0%;
                }

                25% {
                    width: 50%;
                }

                50% {
                    width: 75%;
                }

                75% {
                    width: 85%;
                }

                100% {
                    width: 100%;
                }
            }
        }
    }
}
