@media (min-width: 768px) and (max-width: 1024px) {
    .expand-body {
        height: 100% !important;
    }

    .login-page {
        .main-div {
            background-size: cover;
            border-radius: 15px;
        }
    }

    header {
        display: none;
    }

    aside {
        display: none;
    }

    footer {
        display: none;
    }

    .sub-header {
        margin: 0;
        overflow: auto;
        overflow-y: hidden;

        .right-side {
            z-index: 1071;

            .sub-header-ul {
                .sub-header-li {
                    &.show {
                        ul.dropdown-menu {
                            top: 30px !important;
                            left: 0 !important;
                        }
                    }

                    .dropleft {
                        .dropdown-menu {
                            display: none !important;

                            &.show {
                                display: block !important;
                            }
                        }
                    }
                }
            }
        }

        .left-side {
            .sub-header-ul {
                li {
                    white-space: nowrap;
                }
            }
        }
    }

    .main-wrapper {
        height: 85vh;
    }

    .content-wrapper {
        padding-left: 0 !important;

        .dataTables_wrapper {
            height: auto !important;

            .bottom {
                position: unset !important;
                width: auto !important;

                ul.pagination {
                    padding-top: 0;
                }
            }
        }
    }

    .page-form {
        .form-group {
            // .input-group {
            //     flex-wrap: wrap;
            // }

            label {
                white-space: nowrap;
            }
        }
    }

    .advance-search-modal,
    .shortcut-modal {
        .modal-dialog {
            max-width: 80%;

            .modal-content {
                .modal-body {
                    padding: 0;
                    align-items: center;
                }
            }
        }
    }

    .box {
        .box-footer {
            background-color: white;
            bottom: 20px;
            width: 93%;

            .box-shortcut {
                display: none;
            }
        }
    }

    .responsive-wireframe {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        align-items: center;
        position: relative;

        .page-name {
            position: absolute;
            left: 5rem;
        }

        .settings-modal {
            display: none !important;
        }

        &.settings-sidebar-on {
            .sidebar-li {
                display: block;
            }

            #responsive-wireframe-sidebar {
                a[data-toggle="modal"] {
                    display: none;
                }

                .settings-modal {
                    display: block !important;
                }
            }
        }

        .responsive-wireframe-sidebar {
            height: 100%;
            width: 0;
            position: fixed;
            z-index: 1052;
            top: 0;
            left: 0;
            background-color: white;
            overflow-x: hidden;
            transition: 0.5s;
            padding-top: 60px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            box-shadow: 4px 2px 12px -1px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: 4px 2px 12px -1px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 4px 2px 12px -1px rgba(0, 0, 0, 0.2);

            .closebtn {
                position: absolute;
                top: 0;
                right: 25px;
                font-size: 36px;
                margin-left: 50px;
            }

            a[data-toggle="modal"],
            .user-name,
            .logout {
                font-size: 18px;
                color: $primary-darker;
                padding: 8px 8px 8px 32px;
                text-decoration: none;
                display: block;
                transition: 0.3s;
                width: 210px;
            }

            .user-name {
                border-bottom: 1px solid #e5e5e5;
                width: auto !important;

                img {
                    border: 1px solid black;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                }

                h6 {
                    width: 180px;
                }
            }

            .modal-dialog {
                width: 50%;
                margin: 0 auto;
            }

            .modal-content {
                background-color: unset;
                border: none;
                box-shadow: unset;

                .modal-body {
                    background-color: transparent;

                    &.sidebar-li {
                        margin-left: 0;
                    }

                    ::-webkit-scrollbar {
                        display: none !important;
                    }
                }

                .inner-ul {
                    display: block;
                    width: 100%;
                    border-top-left-radius: 15px !important;
                    max-height: 500px;
                    overflow-y: auto;
                    position: unset;
                    background-color: white;

                    h5 {
                        color: $primary-dark;
                    }

                    .inner-li {
                        .letter-sentence {
                            align-items: center;

                            .img-div {
                                border: 1px solid $primary-dark;
                                padding: 10px;

                                img {
                                    width: 20px;
                                    height: 20px;
                                }
                            }

                            .content-div {
                                .heading {
                                    color: $primary-dark !important;
                                    font-size: 16px;
                                    font-weight: 400;
                                }

                                .description {
                                    display: none;
                                }
                            }

                            &:hover {
                                background-color: $primary-dark;

                                .heading {
                                    color: white !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .bar {
            svg {
                width: 25px;
            }
        }

        .logo {
            img {
                border-radius: 10px;
                width: 45px;
            }
        }

        .responsive-wireframe-multinav {
            font-size: 24px;

            .more-img {
                height: 30px;
                width: 30px;
            }

            #multinav-modal {
                &.fade {
                    transition: opacity 1s linear;
                }

                .modal-dialog {
                    width: 50%;
                    margin: 0 auto;
                }

                svg {
                    border: 1px solid black;
                    width: 40px;
                    height: 40px;
                    border-radius: 7px;
                    background-color: white;
                    padding: 0 10px;
                }

                .modal-content {
                    border-radius: 15px;

                    a[data-toggle="modal"],
                    .anchor {
                        font-size: 16px;
                        color: black;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &:focus {
                            color: black !important;
                        }
                    }

                    .multiple-items {
                        .slick-slide {
                            div:nth-of-type(1) {
                                div {
                                    a {
                                        margin-bottom: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .modal-content {
                .dropdown-menu {
                    display: block;
                    position: unset;
                    transform: unset;
                }
            }

            .quickadd {

                .modal-content {
                    border-radius: 15px;

                    ::-webkit-scrollbar {
                        display: none !important;
                    }

                    .modal-body {
                        width: 100%;
                        overflow-x: scroll;
                    }
                }
            }

            #favourite,
            #pin {

                .modal-content {
                    border-radius: 15px;
                }

                .bookmark-ul {
                    top: 0;

                    .bookmark-li {
                        .inner-a {
                            .letter-sentence {
                                p {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            #support,
            #reminder,
            #notification,
            #help {
                .modal-content {
                    border-radius: 15px;

                    .support-div {
                        width: 100%;
                    }
                }
            }

            #support {
                .title {
                    left: 12rem;
                    top: 2rem;
                }
            }

            #support,
            #help,
            #reminder,
            #notification,
            #favourite {
                .modal-dialog {
                    max-width: max-content;
                }
            }
        }
    }

    .tablesorter {
        overflow: auto;
        display: block;
    }

    .ticket-box-body {
        .ticket-control-row {
            height: auto;
        }
    }

    .form-group {
        .tab-inner-content {
            margin-left: 122px;
        }

        .addondesc {
            margin-left: 57px;
        }

        .sales-order-anchor {
            margin-left: 65px !important;
        }

        .sales-order-summary {
            margin-top: -62px !important;
        }
    }

    .ticket-inner-page {
        .main-wrapper {
            height: auto !important;
        }
    }

    .ticketSearchBox {
        .search_arrow_box {
            max-width: 50%;

            &::after,
            &::before {
                left: 55%;
            }
        }
    }

    .ticket-list-content {
        .tab-content {
            .admin-main-content {
                .info-box {
                    .info-box-icon {
                        img {
                            margin: 0;
                        }
                    }

                    .info-box-content {
                        .info-box-number {
                            padding-top: 25%;
                        }
                    }
                }
            }
        }

        .nav-tabs {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .ticket-box {
            .ticket-box-body {
                .ticket-time-list {
                    margin-right: 0;
                }

                .ticket-control-row {
                    .row {
                        flex-wrap: nowrap;
                        overflow-x: auto;
                        max-width: max-content;
                        margin: 0 -26px !important;

                        .priority {
                            white-space: nowrap;
                        }

                        .duedate {
                            width: 15%;
                            max-width: 25%;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .diff-pagination {
            .box-footer {
                width: calc(100% - 56px);
                bottom: 20px;
            }
        }
    }

    .edit-meeting-ticket {
        .page-form {
            .form-group {
                .ajax-upload-dragdrop {
                    width: 230px !important;
                }
            }
        }
    }

    .ticket-inner-content {
        .ticket-inner-left {
            height: auto !important;

            .ticket-order {
                margin-bottom: 0;

                .tracking-block {
                    display: flex;

                    .tracking {
                        width: auto;
                    }
                }
            }
        }

        .ticket-inner-right {
            .tab-box {
                .nav-tabs {
                    white-space: nowrap;

                    .ticket-tab {
                        a {
                            padding: 4px;
                        }
                    }
                }

                .tab-content {
                    .tab-pane {
                        margin-bottom: 19rem;

                        .table-box {
                            table {
                                display: table;
                            }
                        }

                        .box-footer {
                            width: 90%;
                            bottom: 20px;
                        }
                    }
                }
            }
        }
    }

    .inventory-dashboard-row {
        .header-box {
            .info-box {
                .header-box-label {
                    .box-amount {
                        padding: 5px 5px 7px 5px;
                    }

                    .header-box-text {
                        font-size: 13px !important;
                        padding-left: 15px !important;
                        padding-top: 14px;
                    }
                }
            }
        }
    }

    .sales-dashboard-row {
        .header-box {
            .info-box {
                .new-box-amount {
                    padding: 5px 10px 10px 95px !important;
                }
            }
        }
    }

   .support-form {
        div#mobile_screen_loading {
            width: 350px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            top: 0%;
            z-index: 1;

            .mobile-screen-loader {
                background: $primary-dark;
                height: 80px;
                z-index: 1;

                .div#mobile_screen_loading {
                    display: block; 
                }
            }

            div.mobile-progress {
                position: relative;
                height: 0.6rem !important;
                margin: 0 auto;
                border-radius: 10px;

                div.mobile-progress-bar {
                    background-color: $secondary-light;
                    position: absolute;
                    // width: 0px;
                    height: 10px;
                    border-radius: 10px;
                    animation: load 4s infinite linear;
                }
            }

            @keyframes load {
                0% {
                    width: 0%;
                }

                25% {
                    width: 50%;
                }

                50% {
                    width: 75%;
                }

                75% {
                    width: 85%;
                }

                100% {
                    width: 100%;
                }
            }
        }
    }
}
