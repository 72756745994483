// Variables
@import 'variables';
@import 'global';

//FontAwesome
$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// sweet alert
@import "~@sweetalert2/theme-bootstrap-4/bootstrap-4.scss";

@import "~bootstrap-icons/font/bootstrap-icons.scss";

// select2
@import "~select2/src/scss/core";

// bootstrap select2
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";

// flat picker
@import "~flatpickr/dist/flatpickr.min.css";

//date range picker
@import "~daterangepicker/daterangepicker.css";

// jquery ui
@import "~jquery-ui-dist/jquery-ui.min.css";

// summer note
@import "~summernote/dist/summernote-bs4.min.css";

// slick slider
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// for Chart js library
@import "~chart.js/dist/Chart.min.css";

// for jquery sticky notes
@import "../../public/plugin/sticky_notes/css/jquery.stickynote.css";
@import "../../public/plugin/sticky_notes/css/StickyNoteDemo.css";

// for org chart
@import "../../public/plugin/orgchart/jquery.orgchart.css";

// for jquery file upload
@import "~jquery-file-upload/css/uploadfile.css";

//Admin-lte
@import "~admin-lte/build/scss/adminlte.scss";

//custom styles
@import 'fonts';
@import 'pages';
@import 'minor';
@import 'status';
@import 'modals';
@import 'new-modals';
@import 'laptop';
@import 'tablet';
@import 'phone';
@import 'buttons';
//@import 'notifications';
