.nav-tabs-custom,
.diff-pagination {
    #email_setting {
        border: none;
    }

    .box-footer.clearfix {
        position: fixed;
        bottom: 46px;
        width: calc(100% - 110px);
        background-color: #fff;
        padding: 0 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        nav {
            div.flex.justify-between:nth-of-type(1) {
                display: none;
            }

            div.hidden:nth-of-type(2) {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                align-items: center;

                div:nth-of-type(1) {
                    p.leading-5 {
                        margin-bottom: 0;
                        color: $grey-light;
                    }
                }

                div:nth-of-type(2) {
                    span.relative.z-0 {
                        display: flex;
                        align-items: center;
                        box-shadow: unset !important;

                        svg {
                            height: 20px;
                            width: 20px;
                            color: $grey-light;
                        }

                        span[aria-label="&laquo; Previous"]:nth-of-type(1) {
                            span.relative {
                                border: none !important;
                            }
                        }

                        span[aria-label="Next &raquo;"] {
                            span.relative {
                                border: none !important;

                                svg {
                                    margin-top: -2px;
                                }
                            }
                        }

                        span[aria-current="page"] {
                            display: none;
                        }

                        a.relative.inline-flex {
                            display: none;
                        }

                        a.relative.inline-flex:nth-of-type(2) {
                            border: none !important;
                        }

                        a[rel=next] {
                            svg {
                                margin-top: 2px;
                                color: $primary-dark;
                            }
                        }

                        a[rel=prev] {
                            svg {
                                color: $primary-dark;
                            }
                        }

                        a[rel=prev],
                        a[rel=next] {
                            display: block !important;
                            border: none !important;
                        }
                    }
                }
            }
        }
    }
}

.diff-pagination {
    .box-footer {
        display: flex;
        justify-content: flex-end;
    }
}

.rotate {
    svg {
        -moz-transition: all 0.4s linear;
        -webkit-transition: all 0.4s linear;
        transition: all 0.4s linear;
    }
}

.rotate.down {
    svg {
        -moz-transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg);
    }
}

#managecolumn {
    .modal-dialog {
        display: flex;
        justify-content: flex-end;
        max-width: 100%;
        margin-right: 20px;
        margin-top: 15px;
    }

    .modal-content {
        border-radius: 15px;
        box-shadow: unset;
        width: auto;

        #sortable {
            max-height: 570px;
            overflow-y: auto;
            min-height: auto;
        }

        .ui-icon-arrowthick-2-n-s {
            background-image: url('../../public/images/svg/drag-drop.svg');
            background-position: unset;
            background-size: 45%;
            margin-top: 0.5px;
        }

        .ui-state-default {
            text-transform: capitalize;
            border: none;
            padding: 5px 10px;
            color: $primary-dark;
            background-color: white;

            label {
                font-size: 14px;
            }

            &:hover {
                background-color: rgb(238 238 238);
                color: $black;
                border-radius: 20px;
            }
        }

        .modal-footer {
            justify-content: center;

            // input.save-button,
            // .btn.cancel-button {
            //     background-size: 200% 100%;
            //     background-position: right bottom;
            //     transition: all .3s ease-out;
            //     border-radius: 5px !important;
            //     width: 70px;
            //     height: 40px;

            //     &:hover {
            //         background-position: left bottom;
            //     }
            // }

            // .save-button {
            //     background: linear-gradient(to right, white 50%, $primary-dark 50%);
            //     border: 1px solid $primary-dark !important;
            //     background-color: $secondary-lighter !important;

            //     &:hover {
            //         color: $primary-dark !important;
            //         border: 1px solid $primary-dark !important;
            //     }
            // }

            .cancel-button {
                // background: linear-gradient(to right, white 50%, $secondary-lighter 50%);
                // border: 1px solid $secondary-lighter !important;
                // color: $primary-dark;

                // &:hover {
                //     border: 1px solid $secondary-lighter !important;
                // }
            }
        }
    }
}

.table-dropdown {
    border-radius: 15px;

    .ui-state-default {
        text-transform: capitalize;
        border: none;
        padding: 5px 10px;
        background-color: transparent;

        &:hover {
            background-color: #eeeeee;
            color: #000;
            border-radius: 20px;
        }

        input {
            margin-right: 10px;
            vertical-align: middle;
        }
    }

    .restore_default {
        border-radius: 20px;
        background-color: $primary-dark !important;

        &:hover {
            color: $white !important;
        }
    }
}

.tablesorter {
    tbody {
        tr {
            td {
                .dropdown {
                    ul.dropdown-menu {
                        border-radius: 10px;

                        li {
                            a {
                                padding: 10px 20px;

                                &:hover {
                                    background-color: #EEEEEE;
                                }
                            }

                            a[data-method="delete"] {
                                &:hover {
                                    background-color: #F8D7DA;
                                    color: red !important;
                                }
                            }
                        }
                    }
                }

                .invoice-approve-button {
                    color: #42af72;
                    border: 1px solid #42af72;
                    background: linear-gradient(to right, #42af72 50%, white 50%);
                    background-size: 200% 100%;
                    background-position: right bottom;
                    transition: all .3s ease-out;

                    &:hover {
                        background-position: left bottom;
                        color: #fff !important;
                    }
                }

                .invoice-reject-button {
                    color: #FF5C5C;
                    border: 1px solid #FF5C5C;
                    background: linear-gradient(to right, #FF5C5C 50%, white 50%);
                    background-size: 200% 100%;
                    background-position: right bottom;
                    transition: all .3s ease-out;

                    &:hover {
                        background-position: left bottom;
                        color: #fff !important;
                    }
                }
            }
        }
    }
}

.city-grade-modal {
    .input-group {
        .input-group-append {
            .input-group-text {
                width: 60px;
                justify-content: center;
            }
        }
    }
}

.nav-tabs-custom {
    &.same-page-tab {
        .ticket-tab {
            a {

                &.active,
                &:hover,
                &:active {
                    background-color: $secondary-light !important;
                }
            }
        }
    }
}

.collapse-menu {
    i {
        font-size: 11px;
    }
}

.collapse-inner-menu {
    margin-top: 8px;

    li {
        text-transform: capitalize;
        margin-left: 20px !important;

        &::before {
            content: "\f111" !important;
            font-size: 7px !important;
        }
    }
}

.datatables {
    tbody {
        td {
            .proposal_no {
                a {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

@keyframes shine {
    0% {
        background-position: 0;
    }

    60% {
        background-position: 180px;
    }

    100% {
        background-position: 180px;
    }
}

.switch-to-old {
    a {
        color: hotpink;

        &:hover,
        &:focus {
            color: hotpink !important;
        }
    }
}

.data-table-page {
    overflow: unset !important;
}

.custom-addmore-table {

    td,
    th {
        vertical-align: middle;
        padding: 6px;

        input[type="file"] {
            border: none;
        }
    }
}

.main-wrapper {
    .content {
        .box-data-table.box-body {
            padding: 0 0 10px 0 !important;

            .dataTables_wrapper {
                tbody {
                    .btn-group {
                        .dropdown-menu {
                            &.show {
                                left: -38px !important;
                                top: -5px !important;
                            }
                        }
                    }
                }
            }
        }

    }
}

tbody {
    tr {
        td {
            .badge.bg-green {
                display: none;
                margin-top: -1px;
                padding: 3px 5px;
                border-radius: 5px;
                margin-left: 2px;
                font-size: 10px;
                vertical-align: middle;
            }

            &:hover {
                .badge.bg-green {
                    display: inline-block;
                }
            }
        }
    }
}

.main-wrapper {
    .content {
        .dataTables_wrapper {
            thead {
                tr {
                    th {
                        .select2 {
                            width: 100% !important;
                        }
                    }

                    th.w-250 {
                        width: 250px !important;
                    }

                    th.w-200 {
                        width: 200px !important;
                    }

                    th.w-175 {
                        width: 175px !important;
                    }

                    th.w-150 {
                        width: 150px !important;
                    }
                }
            }

            tbody {
                tr {
                    td.w-250 {
                        width: 250px !important;

                        img {
                            width: 40px;
                        }
                    }

                    td.w-200 {
                        width: 200px !important;

                        img {
                            width: 40px;
                        }
                    }

                    td.w-175 {
                        width: 175px !important;

                        img {
                            width: 40px;
                        }
                    }

                    td.w-150 {
                        width: 150px !important;

                        img {
                            width: 40px;
                        }
                    }
                }
            }
        }
    }
}

#dash-docModal {
    .modal-content {
        border: none;
    }

    .modal-header {
        border: none;

        .header {
            margin-top: 0;
            padding-right: 0;
        }
    }

    .modal-body {
        .main-table {
            height: auto;
        }

        table {
            thead {
                th {
                    white-space: nowrap;
                }
            }
        }

        #dashboard_document_modal_body {

            textarea,
            input {
                width: 150px;
            }

            .select2-container {
                width: 150px !important;

                .select2-search__field {
                    border: unset;
                }

                &.select2-container--focus {
                    textarea {
                        border: unset;
                    }
                }
            }
        }
    }
}

.daterangepicker {
    font-family: "Proxima Nova Regular" !important;
    border-radius: unset !important;

    .drp-calendar {
        thead {
            th {
                select {
                    border-radius: unset;
                }
            }

            tr:nth-of-type(2) {
                th {
                    color: #0000008a;
                }
            }
        }

        tr {
            td {
                height: 37px;
                width: 37px;
                border-radius: unset !important;
                font-size: 14px;

                &.active {
                    background-color: $secondary-darker;
                    color: $white;

                    &:hover {
                        background-color: $secondary-darker;
                    }
                }

                &:hover {
                    background-color: #e6e6e6;
                }
            }
        }
    }

    .drp-buttons {
        button {
            border-radius: unset !important;
        }

        .applyBtn {
            background-color: $secondary-darker;
            border-color: unset;
        }
    }
}

.flatpickr-wrapper {
    width: 100%;
}

.LeadAll {
    .lead-menu {
        border-radius: 10px;
        margin: 0;
        padding: 0;

        li {
            a {
                &:hover {
                    background-color: #eee;
                }
            }
        }

        li:nth-of-type(1) {
            a {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }

        li:nth-last-of-type(1) {
            a {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }
}

table.tablesorter {
    td {
        .badge-danger {
            font-size: 13px;
            padding: 5px 10px;
            background-color: #ff3a35;
        }
    }
}

.LeadAll {
    .btn {
        background-color: unset;
        border: 1px solid #808589;
        padding: 4px;
        height: 25px;
        width: 25px;
        text-align: center;
        line-height: 1.2;
    }
}

.table-no-action {
    .dataTables_wrapper {
        table {
            tbody {
                tr {
                    td:nth-last-of-type(1) {
                        width: auto !important;
                    }
                }
            }
        }
    }
}

ul.custom-lis {
    margin-top: 0;
    border-radius: 10px;
    padding: 0;

    li {
        a {
            padding: 7px 20px;
            font-size: 14px;
            color: $black;

            .bi {
                margin-right: 10px;
            }

            &:hover {
                background-color: #EEEEEE;
            }
        }

        a[data-method="delete"] {
            &:hover {
                background-color: #fbebec;
                color: #ff3b3c !important;
            }
        }
    }

    li:nth-of-type(1) {
        a {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    li:nth-last-of-type(1) {
        a {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

.mass_update {
    .btn-group {
        .dropdown-toggle {

            &:active,
            &:focus,
            &:hover {
                background-color: #eee !important;
                border-color: black;
            }

            &::after {
                margin-left: 0;
                vertical-align: middle;
            }
        }
    }
}


.reminder-div {
    .tab {
        display: none;
    }

    .tab-active {
        display: block;
    }
}

.sub-header {
    .left-side {
        .sub-header-ul {
            .nav-menu {
                a[type="button"] {
                    -webkit-appearance: none;
                }
            }
        }
    }
}

.three-level-menu {
    .inner-level-one {
        &:hover {
            .dropdown-menu {
                display: none !important;
            }

            .inner-level-one-dropdown {
                display: block !important;
            }
        }
    }

    .inner-level-two {
        &:hover {
            .inner-level-two-dropdown {
                display: block !important;
            }
        }
    }

}

.custom-lis {
    .dropdown-submenu {
        &:hover {
            .dropdown-menu {
                display: block;
                padding: 0;
                border-radius: 10px;
            }
        }
    }
}

.nested-dropdown {
    .dropdown-submenu {
        &:hover {
            .dropdown-menu {
                display: block;
                padding: 0;
                border-radius: 10px;
                left: -160px;
                top: 0;
            }
        }
    }
}

.snooze-modal-noti {
    position: absolute;
    top: 0;
    overflow: unset;
    left: 50%;
    top: 80%;
    transform: translate(-50%, -50%);

    .modal-dialog {
        margin: 0;

        .modal-content {
            width: 60%;
            border-radius: 10px;
            margin: 0 auto;
            border: 1px solid gray !important;
            box-shadow: 4px 6px #f3f2f2;
        }
    }
}

#display_notification_div {
    position: absolute;
    top: -1rem;
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
    height: calc(100vh - 80px);
    overflow-y: auto;
    z-index: 1041 !important;
    width: 100%;
    left: 0;
    overflow-x: hidden;

    #button-control {
        position: fixed;
        left: 50%;
        transform: translate(-50%);
        z-index: 1041;
        top: 20px;
    }

    .div-notification {
        z-index: 1035;
        width: 25%;

        .inner-div {
            position: relative;

            .alert {
                padding-right: 20px;
                margin: 10px !important;
                border-radius: 10px;
                border: none;
                position: relative;

                table {
                    display: table;
                }

                .modal {
                    position: absolute;
                    top: 0;
                    overflow: unset;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    .modal-dialog {
                        margin: 0;

                        .modal-content {
                            width: 60%;
                            border-radius: 10px;
                            margin: 0 auto;
                        }
                    }
                }

                strong {
                    width: 80%;
                    display: block;
                }

                .fa-times {
                    position: absolute;
                    top: 15px;
                    right: 1.5rem;
                    cursor: pointer;
                }

                .fa-bell {
                    position: absolute;
                    top: 15px;
                    cursor: pointer;
                    right: 3rem;
                }

                .fa-download {
                    position: initial;
                    top: 15px;
                    cursor: pointer;
                    right: 3rem;
                }

                table {
                    background-color: white;
                    border-radius: 10px;
                    border: none;

                    tr:nth-of-type(1) {
                        display: none;
                    }

                    tr {

                        th,
                        td {
                            border: none;
                            border-bottom: 1px solid #dee2e6;
                        }
                    }

                    .change-class {
                        color: red;
                    }
                }
            }

            .alert-warning {
                table {
                    th {
                        color: $warning;
                    }
                }
            }

            .alert-danger {
                table {
                    th {
                        color: $danger;
                    }
                }
            }

            .alert-success {
                table {
                    th {
                        color: $success;
                    }
                }
            }

            .alert-info {
                table {
                    th {
                        color: $info;
                    }
                }
            }
        }
    }
}

.noti-anchor-tag {
    cursor: pointer;
}

#update_pending_noti_counter {
    position: absolute;
    top: -13px;
    left: 8px;
}

.nav-tabs-custom {
    .purchase-content {
        .form-group {
            label {
                font-family: "proxima nova Bold";
            }
        }
    }
}

.search-ticket-tab {
    display: flex;
    justify-content: end;

    .search_arrow_box {
        top: 2rem;
    }
}

.ticket-metting-mom {
    a {
        svg {
            height: 18px;
            width: 18px;
            fill: $primary-darker;
        }
    }
}

.ticket-search-box {
    display: flex;
    justify-content: end;

    .filter-in {
        &.search_arrow_box {

            &::before,
            &::after {
                right: 10%;
                left: unset;
            }
        }
    }

    .nav-pills.search {
        li {
            width: 100%;
        }
    }
}

.modal-body {
    form {
        .nav-tabs-custom {
            .nav-tabs {
                .active {
                    background-color: $secondary-light !important;
                }
            }
        }
    }
}

.ticket-inner-content {
    .ticket-inner-left {
        *::-webkit-scrollbar {
            width: 13px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #d6dee1 !important;
            border-radius: 20px !important;
            border: 6px solid transparent !important;
            background-clip: content-box !important;
        }

        ::-webkit-scrollbar-track {
            background-color: transparent !important;
        }


        .ticket-order {
            overflow-x: auto;

            .tracking-block {
                margin-bottom: 10px;
            }

        }
    }
}

.paginate-with-btn {
    .box-footer {
        z-index: 1;
    }

    .three-btn {
        position: absolute;
        left: 0;
    }

    .order-three-btn {
        right: 0;
        left: auto;
    }
}

.user-icon {
    .fa-user {
        position: absolute;
        right: 8px;
        padding: 10px;
    }
}

.alert-success {
    strong {
        a {
            color: #155724;
        }
    }
}

.hr-office-timmings {
    .form-control.timepicker[disabled] {
        background-color: #e9ecef !important;
    }
}

.paginate-with-btn {
    .order-report-footer {
        border-top: unset !important;
        bottom: 65px !important;
    }
}

.attendance-report-table {
    .table {
        display: block;
        overflow-x: auto;
        height: 350px;

        thead {
            tr {
                td {
                    position: sticky;
                    top: -1px;
                    background-color: white;
                    z-index: 1;
                }

                td:nth-child(-n+4) {
                    z-index: 2;
                }
            }
        }

        tbody {
            tr {
                td:nth-child(-n+4) {
                    position: sticky;
                    background-color: white;
                    z-index: 1;
                }
            }
        }

        thead,
        tbody {
            tr {
                td:nth-child(1) {
                    left: -1px;
                }

                td:nth-child(2) {
                    left: 30px;
                }

                td:nth-child(3) {
                    left: 98px;
                }

                td:nth-child(4) {
                    left: 167px;
                }
            }
        }
    }
}

.custom-disabled[disabled] {
    pointer-events: none;
    cursor: not-allowed;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

.note-editable ul {
    list-style: disc !important;
    list-style-position: inside !important;
}

.note-editable ol {
    list-style: decimal !important;
    list-style-position: inside !important;
}

.checkbox-grid {
    tbody {
        tr {
            td {
                label {
                    margin-bottom: 0;
                }
            }
        }
    }

    input[type=checkbox] {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        vertical-align: middle;

        &::before {
            font-family: "Font Awesome 5 Free";
            content: "\f00c";
            font-size: 10px;
            color: transparent !important;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            border: 1px solid #ced4da;
        }
    }

    input[type=checkbox]:checked:before {
        color: white !important;
        background-color: $secondary-darker;
    }

    thead {
        tr {
            th {
                .btn-group {
                    .dropdown-toggle {
                        background-color: unset;
                        border: unset;

                        &::after {
                            display: none;
                        }

                        .check-more {
                            width: 16px;
                            height: 20px;
                            object-fit: contain;
                        }
                    }

                    .dropdown-menu {
                        border-radius: 10px;
                        border: unset;
                        box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

                        li:hover {
                            background-color: #eeeeee !important;
                        }
                    }
                }
            }
        }
    }
}
