* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {

    &:hover,
    &:focus {
        text-decoration: none !important;
        color: $primary-dark !important;
        box-shadow: none !important;
    }
}

.text-primary-darker {
    color: $primary-darker!important;
}

.text-primary-dark {
    color: $primary-dark;
}

.text-primary-light {
    color: $primary-light;
}

.text-dark-grey {
    color: $grey-dark;
}

.text-light-grey {
    color: $grey-light;
}

.text-unique {
    color: $unique;
}

.text-bold {
    font-family: "Proxima Nova Bold";
}

.no-dots {
    list-style-type: none;
}

.text-black {
    color: black;
}

.modal-600 {
    max-width: 600px !important;
}

.modal-700 {
    max-width: 700px !important;
}

.btlr {
    border-top-left-radius: 15px;
}

.bblr {
    border-bottom-left-radius: 15px;
}

.fs-14 {
    font-size: 0.875rem;
}

.fs-16 {
    font-size: 1rem;
}

.br-15 {
    border-radius: 15px !important;
}

.hoverImage {
    .hover-img {
        display: none;
    }

    &:hover {
        display: flex;
        justify-content: center;

        .hover-img {
            display: block;
        }

        .active-img {
            display: none;
        }
    }
}

.activeImage {
    .hover-img {
        display: none;
    }

    &:hover {
        .hover-img {
            display: block;
        }

        .active-img {
            display: none;
        }
    }
}

*::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    border-radius: 30px;
    background-color: #D3DEEE;
}

*::-webkit-scrollbar-thumb {
    border: 3px solid #D3DEEE;
    border-radius: 20px;
    background-color: white;
}

div.dropdown-menu-center,
.dropdown-menu-center {
    right: auto;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.sidebar-ul {
    .dropright {
        &:hover {
            >.dropdown-menu {
                display: block;
            }
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }
}

header {
    .left-side {
        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }

    .right-side {

        .header-li.show,
        .meeting.add-box-shadow,
        .ticket.add-box-shadow {
            border-radius: 50%;
            box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
            text-align: center;
        }

        .header-li {
            .d-lg-block.show {
                border-radius: 50%;
                box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
                text-align: center;
            }
        }

        .quickadd,
        .bookmark,
        .calendar,
        .email,
        .notification {
            .dropdown-toggle {
                &::after {
                    display: none;
                }
            }
        }
    }
}

.sub-header {

    .multi-select,
    .page-tip,
    .view-report,
    .period {
        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }
}

footer {
    .dropdown-toggle.main-anchor {
        &::after {
            display: none;
        }
    }

    .footer-li {
        .main-anchor {

            svg,
            .cls-1 {
                fill: #4e6b97;
            }

            &:hover {

                svg,
                .cls-1 {
                    fill: white;
                }
            }

            i,
            .footer-bi-icon {
                color: #4e6b97!important;
            }

            &:hover {

                i,
                .footer-bi-icon {
                    color: white!important;
                }
            }
        }

        &.show {
            .main-anchor {
                svg {
                    fill: white;
                }
                i {
                    color: white!important;
                }
            }
        }
    }

    .footer-li.show {
        background-color: $secondary-darker;

        .active-img {
            display: none;
        }

        .hover-img {
            display: block;
        }
    }
}

.box {
    box-shadow: unset !important;
    border-top: none !important;

    .box-footer {
        position: fixed;
        bottom: 51px;
        display: flex;
        // border-top: 1px solid #C8C8C8;
        justify-content: space-between;
        padding: 0;
        width: 92%;

        .box-shortcut {
            display: flex;

            p {
                display: flex;
                align-items: center;
                font-size: 14px;
                padding: 0 20px;
                border-right: 1px solid #C8C8C8;
            }
        }

        .form-group {
            margin-bottom: 0;
        }
    }
}

.custom-btn-group {
    .dropup.show {
        display: flex !important;

        .dropdown-menu {
            background-color: #eef3fa;
            padding: 0;

            .btn-2 {
                border-top: 1px solid #c7d5eb;
                border-bottom: 1px solid #c7d5eb;
            }

            input {
                color: #1c3f67;

                &:hover {
                    background-color: transparent !important;
                }
            }
        }
    }
}

.tooltip-inner {
    background-color: $primary-light !important;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $primary-light !important;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: $primary-light !important;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: $primary-light !important;
}

.bs-tooltip-bottom {
    left: 10px;
    top: 10px !important;
}

.bs-tooltip-top {
    left: 10px;
    top: -5px !important;
}

.bs-tooltip-custom {
    left: 50px;
    top: 50px !important;
}

.upside {
    .inner-ul.dropdown-menu {
        top: auto;
        bottom: 0;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 10px !important;
    }
}

.more-icon {
    img {
        width: 17px;
        margin-top: -2px;
    }
}

.back-menu {
    display: none;
}

.large-inner-menu {
    display: none;
}

.new-sub-menu {
    .sidebar-li {
        display: none;
    }

    .later-hide {
        display: none !important;
    }

    .back-menu {
        display: block;
        margin-left: 5px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        &.hoverImage {
            background-color: #d6e1f2;
        }


        .fa-arrow-left {
            color: $primary-dark;
        }

        &:hover {

            .fa-arrow-left {
                color: white;
            }
        }
    }

    li.large-menu {
        display: flex;
        justify-content: center;
        padding: 2px 0!important;

        &:hover {
            ul.inner-ul {
                display: flex !important;
            }
        }
    }

    .large-menu {
        display: block;

        ul.inner-ul {
            &.show {
                display: none !important;
            }
        }

    }
}

.settings-sidebar-on {
    .back-menu {
        display: block;
        margin-left: 5px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        &.hoverImage {
            background-color: #d6e1f2;
        }

        .fa-arrow-left {
            color: $primary-dark;
        }

        &:hover {

            .fa-arrow-left {
                color: white;
            }
        }
    }
}

.gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -20px;
    // background-color: #fff;
    // filter: contrast(20);

    .dot {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 12px;
        left: 15px;
        // filter: blur(4px);
        background-color: #5575a7;
        border-radius: 50%;
        transform: translateX(0);
        animation: dot 2.8s infinite;
    }

    .dots {
        transform: translateX(0);
        margin-top: 12px;
        margin-left: 31px;
        animation: dots 2.8s infinite;

        span {
            display: block;
            float: left;
            width: 16px;
            height: 16px;
            margin-left: 16px;
            background-color: #5575a7;
            border-radius: 50%;
        }
    }
}

@-moz-keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@-webkit-keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@-o-keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@keyframes dot {
    50% {
        transform: translateX(96px);
    }
}

@-moz-keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}

@-webkit-keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}

@-o-keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}

@keyframes dots {
    50% {
        transform: translateX(-31px);
    }
}

.settings-sidebar-on {
    .fa-arrow-left {
        color: $primary-dark;
    }

    .back-menu {
        &:hover {
            .hover-img {
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            .fa-arrow-left {
                color: white;
            }
        }
    }

    .sidebar-li {
        display: none;
    }

    .settings-li,
    .back-menu {
        display: block;
    }

    .settings-li {
        &:hover {
            display: flex;
        }
    }

    a.back-menu {
        padding: 0 !important;
    }
}

.settings-li {
    display: none;
}

svg {
    pointer-events: none;
}

.main-th-tl {
    display: flex;
    align-items: center;
    width: 100% !important;
}

.th-tooltip {
    position: relative;
    border-radius: 50%;
    border: 2px solid rgb(128 128 128);
    text-align: center;
    width: 16px;
    height: 16px;
    position: relative;
    margin-top: -2px;

    &::before {
        font-size: 8px;
        color: rgb(128 128 128);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .tooltiptext {
        visibility: hidden;
        width: 80px;
        background-color: $primary-light;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        padding: 5px 0;
        position: absolute;
        z-index: 9999;
        left: -33px;
        bottom: -30px;
        font-weight: 400;
        font-size: 14px;
        font-family: "Proxima Nova Regular";

        &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: rotate(180deg);
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $primary-light transparent transparent transparent;
        }
    }
}

.th-tooltip:hover .tooltiptext {
    visibility: visible;
}


.dataTables_wrapper {
    table {

        thead,
        tbody {
            tr {

                th:nth-last-of-type(1),
                td:nth-last-of-type(1) {
                    width: 0% !important;
                    min-width: 0% !important;

                    div {
                        width: auto !important;
                    }
                }
            }
        }
    }
}

.text-nowrap {
    white-space: nowrap;
}

.datepicker1,
.datepicker1_max_today,
.datetime_picker,
.datetimepicker_mask,
.datetimepicker_mask1,
.timepicker,
.timepicker1 {
    background-color: unset !important;
}

.purchase-content {
    .form-group {
        label {
            font-family: "proxima nova Bold";
        }
    }
}

.w-70 {
    width: 70px !important;
}
