.yes-btn,
.no-btn,
.pending-btn,
.solve-btn,
.inprogress-btn,
.close-btn {
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
}


.yes-btn {
    color: #38c17f !important;
}

.no-btn {
    color: #f06565 !important;
}

.pending-btn {
    color: #f2af1e !important;
}

.solve-btn {
    color: #ff813d !important;
}

.inprogress-btn {
    color: #65a4d1 !important;
}

.close-btn {
    color: #979797 !important;
}
